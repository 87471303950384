<template>
  <footer class="footer">
    <div class="__content">
      <img
        src="@/assets/img/inverted-logo.png"
        class="__brand-logo"
        loading="lazy"
        alt="wetForest-brand-logo"
      />
      <p>contacto@wetforest.com</p>
      <p>Copyright 2021 - <a href="www.google.com">Términos y Condiciones</a> - Todos los derechos reservados</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="scss">
.footer {
  align-items: center;
  background-color: black;
  display: flex;
  justify-content: center;
  height: auto;
  min-height: 104px;
  width: 100%;

  .__content {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    text-align: center;
    padding: 35px 20px;
  }

  .__brand-logo {
    aspect-ratio: 3;
    height: 44px;
  }

  p {
    margin: 12px 0 !important;
  }

  a {
    color: white;
    text-decoration: underline solid white;
  }

  a:hover, a:active {
    color: white;
    filter: brightness(90%);
    text-decoration: underline solid white;
  }

  p {
    font-size: 1rem !important;
  }
}

@media (min-width: 992px) {
  .footer {
    .__content {
      align-items: center;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      max-width: 1200px;
      padding: 0;
    }

    margin: 0 !important;
  }
}
</style>
